export enum ERROR_CODE {
    /** 账号被冻结*/
    LOGIN_FREEZE = 1001,
    /** 账号被拉黑 */
    LOGIN_BLACKLIST = 1002,
    /** token失效 */
    TOKEN_INVALID = 1003,
    /** CPF格式不正确 */
    CPF_INVALID = 10133,
}
